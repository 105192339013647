export default function useMultisafepay() {
  const implementation = {
    doAuthorize: async (
      checkout,
      {
        onApprove: onApproveCallback,
        onChallenge: onChallengeCallback,
        onError: onErrorCallback,
      },
      locale
    ) => {
      const config = useRuntimeConfig()
      console.debug('Multisafepay doAuthorize....')

      let res
      try {
        res = await $fetch(`/api/checkout/${locale}/authorize`, {
          method: 'POST',
          body: {
            sessionId: checkout.id,
          },
        })
      } catch (error) {
        onErrorCallback(error)
        return
      }

      if (res.result == 'REDIRECT') {
        window.location.href = res.url
      }

      if (res.result === 'KO') {
        onErrorCallback(error.message)
      }
    },
  }

  return {
    implementation,
  }
}
